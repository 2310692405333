import React from "react";

import { readState } from "@/__main__/app-state.mjs";
import type { CryptoSymbol } from "@/feature-crypto-decommissioned/constants.mjs";
import { CRYPTO_TOKENS } from "@/feature-crypto-decommissioned/constants.mjs";
import type { CryptoAmountProps } from "@/feature-crypto-decommissioned/CryptoAmount.jsx";
import CryptoAmount from "@/feature-crypto-decommissioned/CryptoAmount.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

type CryptoBalanceProps = Omit<CryptoAmountProps, "value"> & {
  cryptoSymbol: CryptoSymbol;

  /**
   * If a balance is not given, it will be read the current balance from state.
   */
  balance?: bigint | string;
};

export default function CryptoBalance({
  cryptoSymbol,
  balance,
  ...rest
}: CryptoBalanceProps) {
  const {
    crypto: { balances },
  } = useSnapshot(readState);

  const cryptoToken = CRYPTO_TOKENS[cryptoSymbol];

  const currentBalance = balance ?? balances[cryptoToken.ticker] ?? BigInt(0);

  return (
    <CryptoAmount
      cryptoSymbol={cryptoSymbol}
      value={currentBalance}
      {...rest}
    />
  );
}
