import React, { useEffect, useMemo, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { keyframes, styled } from "goober";

import { formatAmount } from "@/feature-crypto-decommissioned/amount-utils.mjs";
import type { CryptoSymbol } from "@/feature-crypto-decommissioned/constants.mjs";
import { CRYPTO_TOKENS } from "@/feature-crypto-decommissioned/constants.mjs";
import { classNames } from "@/util/class-names.mjs";
import { getDecimalSeparator } from "@/util/i18n-helper.mjs";

export enum CryptoRoundingMode {
  /**
   * Will always round decimals down. i.e. 1.9 will become 1.
   */
  RoundDown = 0,

  /**
   * Will round decimals up if they are halfway between numbers. i.e. 1.5 will become 2.
   */
  RoundHalfUp = 1,

  /**
   * Will round decimals up if they are greater than halfway between numbers and down if
   * they are not, however it will round decimals exactly halfway between numbers to the
   * nearest even number. i.e. 1.5 will become 2, and 2.5 will also become 2.
   */
  RoundHalfEven = 2,

  /**
   * Will always round decimals up. i.e. 1.1 will become 2.
   */
  RoundUp = 3,
}

export interface CryptoAmountProps {
  cryptoSymbol: CryptoSymbol;

  value: bigint | string;

  maxDecimals?: number;

  /**
   * The rounding mode to use when rounding the value to the specified number of decimals.
   *
   * @default CryptoRoundingMode.RoundDown
   */
  rounding?: CryptoRoundingMode;

  suffix?: "shortName" | "ticker";
  showTooltip?: boolean;

  onAnimationStateChange?: (isUpdating: boolean) => void;

  className?: string;
}

const ANIMATION = {
  spinTime: 0.2,
  spinCount: 3,
  digitDelay: 0.25,
};

export default function CryptoAmount({
  cryptoSymbol,
  value,
  maxDecimals,
  rounding = CryptoRoundingMode.RoundDown,
  suffix = null,
  showTooltip = false,
  onAnimationStateChange,
  className,
}: CryptoAmountProps) {
  const cryptoToken = CRYPTO_TOKENS[cryptoSymbol];

  const visibleDecimals = maxDecimals ?? cryptoToken.defaultVisibleDecimals;

  const [oldValue, setOldValue] = useState(value);

  const displayValue = useMemo(
    () =>
      typeof value === "string"
        ? value
        : formatAmount(value, {
            decimals: cryptoToken.decimals,
            maxDecimals: visibleDecimals,
            rounding,
          }),
    [value, cryptoToken, visibleDecimals, rounding],
  );

  const oldDisplayValue = useMemo(
    () =>
      typeof oldValue === "string"
        ? oldValue
        : formatAmount(oldValue, {
            decimals: cryptoToken.decimals,
            maxDecimals: visibleDecimals,
            rounding,
          }),
    [oldValue, cryptoToken, visibleDecimals, rounding],
  );

  const [animationStart, setAnimationStart] = useState<number>(null);

  useEffect(() => {
    if (displayValue !== oldDisplayValue) {
      // Don't fire the callback if the animation is already running
      if (animationStart === null) {
        setAnimationStart(Date.now());
      }
    }
  }, [displayValue, oldDisplayValue, animationStart]);

  useEffect(() => {
    onAnimationStateChange?.(animationStart !== null);
  }, [onAnimationStateChange, animationStart]);

  useEffect(() => {
    if (animationStart === null) {
      return;
    }

    const timeout = setTimeout(
      () => {
        setOldValue(value);
        setAnimationStart(null);
      },
      Math.max(
        ANIMATION.spinTime * ANIMATION.spinCount * 1000 -
          // Set the timeout to the time remaining in the animation, so we don't
          // have to wait for the animation to finish a whole cycle before updating.
          // We want the update time to be consistent, even if the value updates
          // multiple times in a row.
          (Date.now() - animationStart),
        // Make sure we can never set a negative timeout
        0,
      ),
    );

    return () => clearTimeout(timeout);
  }, [animationStart, value]);

  const tooltipHtml = useMemo(() => {
    if (!showTooltip) return null;
    if (typeof value === "string") return null;

    const tooltipDisplayValue = formatAmount(value, {
      decimals: cryptoToken.decimals,
    });

    if (tooltipDisplayValue === displayValue) return null;

    return ReactDOMServer.renderToStaticMarkup(
      <ValueTooltip cryptoSymbol={cryptoSymbol} value={tooltipDisplayValue} />,
    );
  }, [cryptoSymbol, cryptoToken.decimals, showTooltip, value, displayValue]);

  const [wholePairs, decimalPairs] = mergeDigitPairs(
    oldDisplayValue,
    displayValue,
  );

  return (
    <ValueText
      {...(showTooltip ? { "data-tooltip": tooltipHtml } : {})}
      {...classNames(animationStart !== null && "animating", className)}
    >
      <span className="digits">
        {wholePairs.map(([oldDigit, digit], i) => {
          const animationDelay = `${-Math.random() * ANIMATION.digitDelay}s`;

          return (
            <div
              key={wholePairs.length - i}
              {...classNames("digit", oldDigit !== digit && "spinning")}
              style={{
                "--animation-delay": animationDelay,
              }}
            >
              <span
                style={{
                  top: `${parseInt(digit) * -1}em`,
                }}
              >
                {"0 1 2 3 4 5 6 7 8 9"}
              </span>

              {digit}
            </div>
          );
        })}
        {decimalPairs.length > 0 && (
          <>
            <div className="separator">{getDecimalSeparator()}</div>

            {decimalPairs.map(([oldDigit, digit], i) => {
              const animationDelay = `${
                -Math.random() * ANIMATION.digitDelay
              }s`;

              return (
                <div
                  key={decimalPairs.length - i}
                  {...classNames("digit", oldDigit !== digit && "spinning")}
                  style={{
                    "--animation-delay": animationDelay,
                  }}
                >
                  <span
                    style={{
                      top: `${parseInt(digit) * -1}em`,
                    }}
                  >
                    {"0 1 2 3 4 5 6 7 8 9"}
                  </span>

                  {digit}
                </div>
              );
            })}
          </>
        )}{" "}
      </span>
      {suffix && (
        <span className="suffix">
          {suffix === "shortName"
            ? cryptoToken.shortName
            : suffix === "ticker"
              ? cryptoToken.ticker
              : ""}
        </span>
      )}
    </ValueText>
  );
}

function mergeDigitPairs(
  oldValue: string,
  currentValue: string,
): Array<Array<[string | null, string | null]>> {
  const oldValueParts = oldValue.split(".");
  const currentValueParts = currentValue.split(".");

  const pairs: Array<Array<[string | null, string | null]>> = [[], []];

  for (let i = 0; i < currentValueParts.length; i++) {
    const oldDigits = oldValueParts[i]?.split("") ?? [];
    const currentDigits = currentValueParts[i]?.split("") ?? [];

    const isDecimal = i === 1;

    for (let j = 0; j < currentDigits.length; j++) {
      pairs[i].push([
        // We want to read the old whole number digits in reverse so we animate
        // the proper digit. i.e. if the old value was 0 and the new value is 10,
        // we want to animate the 1, not the 0 since the 0 is already in place.
        oldDigits[
          isDecimal ? j : j + oldDigits.length - currentDigits.length
        ] ?? null,
        currentDigits[j] ?? null,
      ]);
    }
  }

  return pairs;
}

const animHideDigit = () => keyframes`
  0%, 100% {
    visibility: hidden;
  }
`;

const animSpinDigit = () => keyframes`
  0%, 100% {
    visibility: visible;
  }

  0% {
    top: 0em;
  }

  50% {
    top: -5em;
  }

  100% {
    top: -9em;
  }
`;

const ValueText = styled("div")`
  display: inline-flex;
  gap: var(--sp-1);

  .digits,
  .suffix {
    height: 1em;

    line-height: 1em;
  }

  .digits {
    div {
      --animation-delay: 0s;

      position: relative;

      display: inline-block;

      height: 1em;

      text-align: center;

      overflow: hidden;

      span {
        position: absolute;
        left: 50%;

        transform: translateX(-50%);

        visibility: hidden;
      }

      &.digit.spinning {
        animation: ${animHideDigit} ${ANIMATION.spinTime}s
          ${ANIMATION.spinCount} linear;
        animation-delay: var(--animation-delay);

        span {
          animation: ${animSpinDigit} ${ANIMATION.spinTime}s
            ${ANIMATION.spinCount} linear;
          animation-delay: var(--animation-delay);
        }
      }
    }
  }
`;

function ValueTooltip({ cryptoSymbol, value }) {
  const cryptoToken = CRYPTO_TOKENS[cryptoSymbol];

  return (
    <BalanceTooltipContainer className="type-caption">
      <cryptoToken.Icon size={16} />
      {value} {cryptoToken.shortName}
    </BalanceTooltipContainer>
  );
}

const BalanceTooltipContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--sp-2);

  svg {
    width: var(--sp-4);
    height: var(--sp-4);
  }
`;
