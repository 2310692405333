import React, { useEffect } from "react";

import { readState } from "@/__main__/app-state.mjs";
import type { KryptoniteTransaction } from "@/feature-crypto-decommissioned/models/model-kryptonite-transaction.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

type PendingTransactionStateProps = {
  tx: KryptoniteTransaction | string | null;

  Pending: React.FC<{ tx: KryptoniteTransaction | null }>;
  Submitted?: React.FC<{ tx: KryptoniteTransaction }>;
  Confirmed: React.FC<{ tx: KryptoniteTransaction }>;
  Failed: React.FC<{ tx: KryptoniteTransaction }>;

  onUpdated?: (tx: KryptoniteTransaction) => void;
};

export function PendingTransactionState({
  tx,
  Pending,
  Submitted,
  Confirmed,
  Failed,
  onUpdated,
}: PendingTransactionStateProps) {
  const transactionId = tx && (typeof tx === "string" ? tx : tx.id);

  const {
    crypto: {
      transactions: { [transactionId]: transaction },
    },
  } = useSnapshot(readState);

  useEffect(() => {
    if (!transaction) return;

    onUpdated?.(transaction);
  }, [onUpdated, transaction]);

  if (!transaction) {
    return <Pending tx={null} />;
  }

  switch (transaction.status) {
    case "created":
      return <Pending tx={transaction} />;
    case "submitted":
      if (Submitted) {
        return <Submitted tx={transaction} />;
      }

      return <Pending tx={transaction} />;
    case "confirmed":
      return <Confirmed tx={transaction} />;
    case "failed":
      return <Failed tx={transaction} />;
  }
}
