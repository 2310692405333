import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";
import { Dropdown } from "clutch/src/Dropdown/Dropdown.js";
// eslint-disable-next-line import/namespace
import * as uuid from "uuid";

import { readState } from "@/__main__/app-state.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { EVENT_ERROR } from "@/app/ErrorBoundary.jsx";
import { JoinModalRouter } from "@/feature-battles/join-event-modal-router.mjs";
import { decimalToBigInt } from "@/feature-crypto-decommissioned/amount-utils.mjs";
import type { CryptoSymbol } from "@/feature-crypto-decommissioned/constants.mjs";
import {
  CRYPTO_BLITZ_SYMBOL,
  CRYPTO_TOKENS,
} from "@/feature-crypto-decommissioned/constants.mjs";
import CryptoAmount, {
  CryptoRoundingMode,
} from "@/feature-crypto-decommissioned/CryptoAmount.jsx";
import CryptoBalance from "@/feature-crypto-decommissioned/CryptoBalance.jsx";
import { kryptonite } from "@/feature-crypto-decommissioned/kryptonite.mjs";
import { KryptoniteError } from "@/feature-crypto-decommissioned/models/model-kryptonite-error.mjs";
import type { KryptoniteTransaction } from "@/feature-crypto-decommissioned/models/model-kryptonite-transaction.mjs";
import { PendingTransactionState } from "@/feature-crypto-decommissioned/PendingTransactionState.jsx";
import { cryptoRefs } from "@/feature-crypto-decommissioned/refs.mjs";
import { FormSubmit } from "@/feature-wallet/Shared.jsx";
import ChevronLeft from "@/inline-assets/chevron-left.svg";
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@/shared/Dialog.jsx";
import { useModalRoute } from "@/shared/ModalNavigation.jsx";
import { classNames } from "@/util/class-names.mjs";
import { devError } from "@/util/dev.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

type SubmitState = "idle" | "submitting" | KryptoniteError | "failed";
function useBoltsPurchase(
  productId: string,
  fee: bigint,
  currentBallance: bigint,
  onJoin: () => void,
) {
  // eslint-disable-next-line import/namespace
  const [idempotencyKey] = useState<string>(uuid.v4());
  const [txnId, setTxnId] = useState<string>(null);
  const [submitState, setSubmitState] = useState<SubmitState>("idle");

  const insufficientWallet = fee > currentBallance;
  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (submitState !== "idle" || insufficientWallet) return;
      setSubmitState("submitting");

      const productResponse = await kryptonite.getProduct({ productId });

      if (productResponse instanceof KryptoniteError) {
        devError("Error getting battles product", productResponse);
        eventBus.emit(EVENT_ERROR, {
          error: productResponse,
          tags: ["crypto", "get_battles_event_product"],
        });

        setSubmitState(productResponse);
        return;
      }

      const purchaseResponse = await kryptonite.purchaseProduct({
        idempotencyKey,

        productId: productResponse.id,
        walletId: cryptoRefs.wallet.id,
      });

      if (purchaseResponse instanceof KryptoniteError) {
        devError("Error purchasing event product", purchaseResponse);
        eventBus.emit(EVENT_ERROR, {
          error: purchaseResponse,
          tags: ["crypto", "purchase_battles_event_product"],
        });

        setSubmitState(purchaseResponse);
        return;
      }

      const signature = await cryptoRefs.wallet.signTypedData(
        purchaseResponse.params.domain,
        {
          ForwardRequest: [
            { name: "from", type: "address" },
            { name: "to", type: "address" },
            { name: "value", type: "uint256" },
            { name: "gas", type: "uint256" },
            { name: "nonce", type: "uint256" },
            { name: "data", type: "bytes" },
          ],
        },
        {
          from: purchaseResponse.params.from,
          to: purchaseResponse.params.to,
          value: purchaseResponse.params.value,
          gas: purchaseResponse.params.gas,
          nonce: purchaseResponse.params.nonce,
          data: purchaseResponse.params.data,
        },
      );

      const submitResponse = await kryptonite.submitTransaction({
        request: purchaseResponse,
        signature,
      });

      if (submitResponse instanceof KryptoniteError) {
        devError("Error purchasing event product", submitResponse);
        eventBus.emit(EVENT_ERROR, {
          error: submitResponse,
          tags: ["crypto", "battles_sub_tx_submission"],
        });

        setSubmitState(submitResponse);
        return;
      }

      setTxnId(submitResponse.id);
    },
    [insufficientWallet, idempotencyKey, productId, submitState],
  );

  const onTxnUpdated = useCallback(
    (tranasaction: KryptoniteTransaction) => {
      if (tranasaction.status === "confirmed") {
        onJoin();
      } else if (tranasaction.status === "failed") {
        eventBus.emit(EVENT_ERROR, {
          error: tranasaction,
          tags: ["crypto", "battles_sub_tx_failed"],
        });

        setSubmitState("failed");
      }
    },
    [onJoin],
  );

  return { submitState, txnId, onSubmit, onTxnUpdated, insufficientWallet };
}

// TODO: can I trust that the cryto stuff will always be loaded (on time)?
export default function PayFeeView({
  productId,
  fee,
  isLoading,
  onJoin,
}: {
  productId: string;
  fee: number;
  isLoading: boolean;
  onJoin(): void;
}) {
  const { t } = useTranslation();
  const {
    crypto: { balances },
  } = useSnapshot(readState);
  const [modalRouter] = useModalRoute(JoinModalRouter, "payFee");
  const handleBack = useCallback(() => modalRouter.popRoute(), [modalRouter]);

  const cryptoSymbol: CryptoSymbol = CRYPTO_BLITZ_SYMBOL;
  const cryptoToken = CRYPTO_TOKENS[cryptoSymbol];
  const currentBalance = balances[cryptoToken.ticker] ?? BigInt(0);
  const coinFee = decimalToBigInt(fee, { decimals: cryptoToken.decimals });
  const newBalance = currentBalance - coinFee;

  const { submitState, txnId, onSubmit, onTxnUpdated, insufficientWallet } =
    useBoltsPurchase(productId, coinFee, currentBalance, onJoin);

  return (
    <DialogContent>
      <DialogHeader
        Leading={
          <button type="button" className="icon-btn" onClick={handleBack}>
            <span className="visually-hidden">{t("common:back", "Back")}</span>
            <ChevronLeft aria-hidden />
          </button>
        }
        title={t("battles:payFee", "Pay Entry Fee")}
      />
      <form onSubmit={onSubmit}>
        <DialogBody className="type-subtitle--semi">
          <div className="flex align-center justify-between py-3">
            <span className="shade1">{t("battles:entryFee", "Entry Fee")}</span>
            <div className="flex gap-2 align-center">
              <cryptoToken.Icon size={16} />
              <CryptoAmount
                cryptoSymbol={cryptoSymbol}
                value={coinFee}
                rounding={CryptoRoundingMode.RoundUp}
              />
            </div>
          </div>
          <hr />
          <div className="flex align-center justify-between">
            <span className="shade1">
              {t("common:subscriber.purchaseModal.payment", "Payment")}
            </span>
            <div className="flex justify-end">
              <Dropdown
                name="payment-method"
                required
                items={[
                  {
                    label: cryptoToken.name,
                    value: "bolts",
                    icon: <cryptoToken.Icon size={16} />,
                  },
                ]}
                value={"bolts"}
                textClassName="type-subtitle--semi"
                readOnly
                {...classNames(css`
                  --border-size: 0;
                `)}
              />
            </div>
          </div>
          <hr />
          <div className="flex align-center justify-between py-3">
            <span className="shade1">
              {t("crypto:purchaseTray.currentBalance", "Current Balance")}
            </span>
            <div className="flex justify-end">
              <CryptoBalance
                cryptoSymbol={cryptoSymbol}
                balance={currentBalance}
                suffix="shortName"
                className={insufficientWallet ? "color-red" : "shade0"}
              />
            </div>
          </div>
          <hr />
          <div
            {...classNames(
              "flex align-center justify-between py-3",
              insufficientWallet ? "shade1" : "shade0",
            )}
          >
            <span>{t("crypto:purchaseTray.newBalance", "New Balance")}</span>
            <div className="flex justify-end">
              <CryptoBalance
                cryptoSymbol={cryptoSymbol}
                balance={!insufficientWallet ? newBalance : "-"}
                suffix="shortName"
              />
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          {insufficientWallet ? (
            <Button
              size="medium"
              className="w-full"
              onClick={() => modalRouter.pushRoute("purchaseFlow")}
              bgColor="var(--shade0)"
              bgColorHover="var(--shade0-75)"
              textColor="var(--shade7)"
            >
              {t("common:refillWallet", "Refill Wallet")}
            </Button>
          ) : (
            <Submit
              state={submitState}
              isLoading={isLoading}
              canSubmit={!insufficientWallet}
              txnId={txnId}
              onTxnUpdate={onTxnUpdated}
              onSubmit={onSubmit}
            />
          )}
        </DialogFooter>
      </form>
    </DialogContent>
  );
}

function Submit({
  state,
  isLoading,
  canSubmit,
  txnId,
  onTxnUpdate,
  onSubmit,
}: {
  state: SubmitState;
  isLoading: boolean;
  canSubmit: boolean;
  txnId: string;
  onTxnUpdate(tx: KryptoniteTransaction): void;
  onSubmit(e: React.FormEvent): void;
}) {
  const { t } = useTranslation();

  if (state instanceof KryptoniteError)
    return (
      <div
        className="flex column justify-center"
        style={{ margin: "0 auto", paddingTop: "var(--sp-6)" }}
      >
        <FormSubmit.Error
          size="medium"
          disabled={canSubmit}
          onClick={onSubmit}
          error={state.message}
        />
      </div>
    );
  if (state === "failed")
    return (
      <div
        className="flex column justify-center"
        style={{ margin: "0 auto", paddingTop: "var(--sp-6)" }}
      >
        <FormSubmit.Error
          size="medium"
          disabled={canSubmit}
          onClick={onSubmit}
        />
      </div>
    );
  if (state === "submitting" || txnId)
    return (
      <PendingTransactionState
        tx={txnId}
        Pending={TransactionLoading}
        Submitted={TransactionLoading}
        Confirmed={TransactionLoading}
        Failed={TransactionLoading}
        onUpdated={onTxnUpdate}
      />
    );
  if (isLoading) return <TransactionLoading />;

  return (
    <Button
      type="submit"
      emphasis="high"
      block
      disabled={!canSubmit}
      onClick={onSubmit}
    >
      {t("battles:eventJoin", "Join Event")}
    </Button>
  );
}

function TransactionLoading() {
  return (
    <div
      className="flex column justify-center"
      style={{ margin: "0 auto", paddingTop: "var(--sp-6)" }}
    >
      <FormSubmit.Loading />
    </div>
  );
}
